
import { useStore } from '@/store'
import { reactive, defineComponent, toRefs, onMounted } from 'vue'
import Account from './components/Account.vue'
import UserCard from './components/UserCard.vue'
 interface Profile {
  name: string
  email: string
  avatar: string
  roles: string
  introduction: string
}
export default defineComponent({
  components: {
    Account,
    UserCard
  },
  setup() {
    const defaultProfile: Profile = {
      name: 'Yours Extra.',
      email: 'Loading...',
      avatar: 'Loading...',
      roles: 'Loading...',
      introduction: 'introduction'
    }
    const store = useStore()
    const dataMap = reactive({

      user: defaultProfile,
      activeTab: 'account',

      name: () => {
        return store.state.user.name
      },
      token: () => {
        return store.state.user.token
      },
      email() {
        return store.state.user.email
      },
      avatar() {
        return store.state.user.avatar
      },
      roles() {
        return store.state.user.roles
      },
      introduction() {
        return store.state.user.introduction
      },
      getUser: () => {
        dataMap.user = {
          name: dataMap.name(),
          email: dataMap.email(),
          avatar: dataMap.avatar(),
          roles: dataMap.roles().join(' | '),
          introduction: dataMap.introduction()
        }
      }

    })

    onMounted(() => {
      dataMap.getUser()
    })

    return { ...toRefs(dataMap) }
  }
})
// import { Component, Vue } from 'vue-property-decorator'
// import { UserModule } from '@/store/modules/user'

// const defaultProfile: IProfile = {
//   name: 'Loading...',
//   email: 'Loading...',
//   avatar: 'Loading...',
//   roles: 'Loading...'
// }

// @Component({
//   name: 'Profile',
//   components: {
//     Account,
//     Activity,
//     Timeline,
//     UserCard
//   }
// })
// export default class extends Vue {
//   private user = defaultProfile
//   private activeTab = 'activity'

//   get name() {
//     return UserModule.name
//   }

//   get email() {
//     return UserModule.email
//   }

//   get avatar() {
//     return UserModule.avatar
//   }

//   get roles() {
//     return UserModule.roles
//   }

//   created() {
//     this.getUser()
//   }

//   private getUser() {
//     this.user = {
//       name: this.name,
//       email: this.email,
//       avatar: this.avatar,
//       roles: this.roles.join(' | ')
//     }
//   }
// }
