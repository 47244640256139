<!--
 * @Description:
 * @Autor: scy😊
 * @Date: 2021-01-23 11:03:02
 * @LastEditors: scy😊
 * @LastEditTime: 2021-01-23 11:04:08
-->
<template>
  <el-card style="margin-bottom:20px;">
    <el-form :model="updatedata">
      <el-form-item label="名字">
        <el-input v-model="updatedata.realname" />
      </el-form-item>
      <el-form-item
        label="密码"
        prop="pass"
      >
        <el-input
          v-model="updatedata.password"
          type="password"
          autocomplete="off"
        />
      </el-form-item>
      <el-form-item>
        <EditorImage
          @success-callback="imageSuccessAvator"
        />
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          @click="submit"
        >
          修改
        </el-button>
      </el-form-item>
    </el-form>
  </el-card>
</template>

<script lang="ts">

import { defineComponent, reactive, toRefs } from 'vue'
import { ElMessage } from 'element-plus'
import EditorImage, { UploadObject } from '@/components/tinymce/components/EditorImage.vue'
import { updateUser } from '@/apis/user'
import { useStore } from '@/store'
import { useRoute, useRouter } from 'vue-router'
import { UserActionTypes } from '@/store/modules/user/action-types'
export default defineComponent({
  components: {
    EditorImage
  },
  setup() {
    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    const dataMap = reactive({
      updatedata: {
        realname: '',
        token: store.state.user.token,
        faceimg: '',
        password: ''
      }
    })
    const submit = async() => {
      console.log(dataMap.updatedata)
      const postdata = dataMap.updatedata
      await updateUser({ ...postdata }).then(res => {
        ElMessage.success({
          message: res?.msg,
          type: 'success',
          duration: 5 * 1000
        })
        store.dispatch(UserActionTypes.ACTION_LOGIN_OUT, undefined)
        router.push(`/login?redirect=${route.fullPath}`).catch(err => {
          console.warn(err)
        })
      })
    }
    const imageSuccessAvator = (arr: UploadObject[]) => {
      console.log(arr)
    }
    return {
      ...toRefs(dataMap),
      imageSuccessAvator,
      submit
    }
  }
})
// import { Component, Prop, Vue } from 'vue-property-decorator'
// import { IProfile } from '../index.vue'
// export default class extends Vue {
//   @Prop({ required: true }) private user!: IProfile

//   private submit() {
//     this.$message({
//       message: 'User information has been updated successfully',
//       type: 'success',
//       duration: 5 * 1000
//     })
//   }
// }
</script>
