

import { reactive, defineComponent, toRefs } from 'vue'
import { ElMessage } from 'element-plus'
import { useStore } from '@/store'
export interface UploadObject {
  hasSuccess: boolean
  uid: number
  url: string
  width: number
  height: number
}
export default defineComponent({
  props: {
    color: {
      type: String,
      default: ''
    }

  },
  emits: ['success-callback'],
  setup(_, ctx) {
    const token = useStore().state.user.token
    let listObj: { [key: string]: UploadObject } = {}
    const dataMap = reactive({
      dialogVisible: false,
      defaultFileList: [],
      checkAllSuccess: () => {
        return Object.keys(listObj).every(item => listObj[item].hasSuccess)
      },
      handleSubmi: () => {
        const arr = Object.keys(listObj).map(v => listObj[v])
        if (!dataMap.checkAllSuccess()) {
          ElMessage.success('Please wait for all images to be uploaded successfully. If there is a network problem, please refresh the page and upload again!')
        }
        ctx.emit('success-callback', arr)
        listObj = {}
        dataMap.defaultFileList = []
        dataMap.dialogVisible = false
      },

      handleSuccess: (res: any) => {
        // const uid = file.uid
        // const objKeyArr = Object.keys(listObj)
        // for (let i = 0, len = objKeyArr.length; i < len; i++) {
        //   if (listObj[objKeyArr[i]].uid === uid) {
        //     listObj[objKeyArr[i]].url = response.files.file
        //     listObj[objKeyArr[i]].hasSuccess = true
        //     return
        //   }
        // }
        if (res.code === 0) {
          setTimeout(() => {
            ElMessage({
              type: 'success',
              message: '修改成功'
            })
            dataMap.dialogVisible = false
          }, 1000)
        } else {
          setTimeout(() => {
            ElMessage({
              type: 'success',
              message: res?.msg
            })
            dataMap.dialogVisible = false
          }, 1000)
        }
      },
      handleRemove: (file: any) => {
        const uid = file.uid
        const objKeyArr = Object.keys(listObj)
        for (let i = 0, len = objKeyArr.length; i < len; i++) {
          if (listObj[objKeyArr[i]].uid === uid) {
            delete listObj[objKeyArr[i]]
            return
          }
        }
      },
      onprogress: (event: any, file: any, fileLis: any) => {
        console.log(event, file, fileLis)
      },
      beforeUpload: (file: any) => {
        console.log(file)
        const fileName = file.uid
        const img = new Image()
        img.src = window.URL.createObjectURL(file)
        img.onload = () => {
          listObj[fileName] = {
            hasSuccess: false,
            uid: file.uid,
            url: '',
            width: img.width,
            height: img.height
          }
        }
        console.log(img)
      },
      handleSubmit() {
        const arr = Object.keys(listObj).map(v => listObj[v])
        if (!dataMap.checkAllSuccess()) {
          ElMessage.warning('Please wait for all images to be uploaded successfully. If there is a network problem, please refresh the page and upload again!')
          return
        }
        ctx.emit('success-callback', arr)
        listObj = {}
        dataMap.defaultFileList = []
        dataMap.dialogVisible = false
      }
    })

    return { ...toRefs(dataMap), listObj, token }
  }
})
