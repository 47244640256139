
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'PanThumb',
  props: {
    image: {
      type: String,
      required: true
    },
    width: {
      type: String,
      required: true,
      default: '150px'
    },
    height: {
      type: String,
      required: true,
      default: '150px'
    },
    zIndex: {
      type: Number,
      default: 1
    }
  }
})
