
import { defineComponent, PropType } from 'vue'

import PanThumb from '@/components/pan-thumb/Index.vue'
interface Profile {
  name: string
  email: string
  avatar: string
  roles: string
}
export default defineComponent({
  props: {
    user: {
      type: Object as PropType<Profile>,
      default: () => {
        return {}
      }
    }
  },
  components: {
    PanThumb
  },
  setup() {
    console.log('=')
  }
})
